<template>
  <GeneralCategoryForm
    :id="id"
    :return-route="adminRoute"
    :category-name="categoryName"
    :rest-end-point="restEndPoint"
    @created="$emit('created')"
  />
</template>

<script>
import GeneralCategoryForm from '../generalCategories/GeneralCategoryForm'

import materialUseDefaults from './constants'

export default {
  name: 'AdminMaterialUseCategoryForm',
  components: {
    GeneralCategoryForm
  },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return { ...materialUseDefaults }
  }
}
</script>
